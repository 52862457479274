.error-background {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0 0 0 / 30%);
    backdrop-filter: blur(2.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.error-wrapper {
    color: antiquewhite;
    background-color: rgba(255 255 255 / 85%);
    border: 4px solid white;
    text-align: center;
    border-radius: 10px;
    width: 25%;
    height: 50%;
}

.error-wrapper h2 {
    margin-top: 7vh;
    font-size: 2rem;
    color: #de3c34;
}

.error-message {
    width: 100%;
    height: 30%;
    overflow-wrap: normal;
    word-break: normal;
    color: black;
    font-size: 1.75rem;
}

.error-wrapper button {
    text-transform: capitalize;
}