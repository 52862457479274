/* stylelint-disable  selector-class-pattern */

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --azul: #3f7bbb;
  --azul2: #004693;
  --rojo: #e43433;
  --lila: #4c61b3;
  --beige: #e5daac;
}

h1 {
  margin: 2.5vh;
  padding-left: 1vh;
  font-size: 3vmax;
  letter-spacing: 0.15rem;
}

input {
  font-family: "Fira sans", sans-serif;
  background: none;
  letter-spacing: 125rem;
}

.App {
  background-image: url("../public/colegio_render2.jpg");
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Fira sans", sans-serif;
  overflow: auto;
}

.App-header {
  background-color: #002460;
  width: 88vw;
  border-radius: 3px;
  font-family: "Fira Sans", sans-serif;
  position: fixed;
  top: 3vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5vw;
  font-size: 2.25rem;
}

.info-icon {
  position: relative;
}

.back-background {
  margin: auto 5vw;
  display: flex;
  background-color: rgba(255 255 255 / 95%);
  box-shadow: 4px 4px 1px 1px rgb(0 0 0 / 25%);
  padding: 4.5vh;
  border-radius: 15px;
  outline: solid 20px hsla(0, 0%, 100%, 0.55);
}

form {
  overflow-y: auto;
}

.form-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vh;
  width: 50%;
}

.search-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vh 10vw;
}

#search-container {
  flex-direction: column;
}

.result-container {
  display: flex;
  flex-direction: column;
  height: 30vh;
  width: 100%;
  font-size: 1.1rem;
  color: #002460;
  overflow: scroll;
}

.result-container table {
  padding: 1rem;
}

.result-container table th {
  text-align: left;
}

.MuiTextField-root {
  background: white;
  padding: 0%;
}

.MuiGrid-item {
  max-height: 60px;
}

.logo-container {
  width: 50%;
  margin: auto;
  text-align: center;
}

.logo {
  width: 25vw;
}

.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.button-container {
  margin-top: 2vh;
  display: flex;
}

.validation-alert {
  position: relative;
  top: -16px;
  color: #e43433;
  font-weight: 800;
  font-size: 10px;
  line-height: 0;
  margin: 0;
  padding: 0;
}

.form-advice {
  color: var(--azul2);
  font-size: small;
}

footer {
  position: absolute;
  background-color: var(--rojo);
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 8vh;
  width: 100%;
  bottom: 0;
}

footer p {
  padding-left: 5vw;
}

@media (width <=1100px) {
  .logo-container {
    display: none;
  }

  .form-background {
    width: 100%;
  }

  footer p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (width <=680px) {
  .App-header p {
    display: none;
  }

  .back-background {
    width: 80%;
  }

  .button-container {
    flex-direction: column-reverse;
    align-items: center;
  }
}

@media (height <=630px), (width <=900px) {
  .App-header {
    top: 0;
  }

  footer {
    display: none;
  }

  .back-background {
    height: 90%;
    width: 70%;
    position: relative;
    margin: 13vh auto;
  }

  .menu-dial-btn {
    bottom: 1vh;
  }
}

@media (height <=800px) {
  .App-header p {
    display: none;
  }

  .App {
    overflow-y: auto;
  }
}
