@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --azul: #3F7BBB;
  --azul2: #004693;
  --rojo: #E43433;
  --lila: #4C61B3;
  --gris: #E5DAAC;
}

body {
  margin: 0;
  font-family: "Fira sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Fira sans", sans-serif;
}

.mainframe {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../public/colegio-render.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.mainframe form {
  display: flex;
  flex-direction: column;
  width: 45%;
  background-color: white;
  border: 4px solid var(--lila);
  border-radius: 10px;
  padding: 4vw;
  min-width: 225px;
}

.mainframe button {
  background-color: var(--lila);
  text-transform: capitalize;
  margin: 2vh auto;
}

.login-headline {
  display: flex;
  background-color: #002460;
  color: white;
  font-size: 1.7rem;
  font-family: "Fira sans", sans-serif;;
  font-weight: 100;
  width: 40vw;
  height: 12.5vh;
  justify-content: center;
  padding: 2.5vh 2.5vw;
  align-items: center;
  margin-bottom: 3vh;
}

.login-headline h2 {
  margin: 3vw 3vw 3vw 5vw;
}


@media (width <= 1270px)  {
  .login-headline {
    width: 60vw;
  }
}

@media (width <= 1100px) {
  .login-headline {
    width: 60vw;
    height: 9.5vh;
  }

  .login-headline img {
    height: 10vw;
  }
}


@media (width <= 650px)  {
  .login-headline {
    width: 80vw;
  }

  .login-headline img {
    display: none;
  }

  .login-headline h2 {
    font-size: 1.7rem;
  }
}

@media (height <= 680px) {
  .login-headline img {
    display: none;
  }

}
